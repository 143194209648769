import axios from 'axios';
import { toast } from 'react-toastify';
// import FingerprintJS from '@fingerprintjs/fingerprintjs';

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== 'undefined';

// const url = `https://sandbox.webbooker.co.uk`;
// const url = `https://production.webbooker.co.uk`;
const url = process.env.GATSBY_API_ENDPOINT;

export async function login(data) {
  // const fpPromise = FingerprintJS.load();
  // const fid = await fpPromise.then((fp) => fp.get());
  // const { visitorId } = fid;
  try {
    const request = await axios(`${url}/authenticate`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
        // Fingerprint: visitorId
      },
      data
    });
    return request;
  } catch (error) {
    return error.request;
  }
}

export async function loginWithAzure(data) {
  // const fpPromise = FingerprintJS.load();
  // const fid = await fpPromise.then((fp) => fp.get());
  // const { visitorId } = fid;
  try {
    const request = await axios(`${url}/authenticate/azure`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
        // Fingerprint: visitorId
      },
      data
    });
    return request;
  } catch (error) {
    return error.request;
  }
}

export async function loginWithGoogle(accessToken) {
  // const fpPromise = FingerprintJS.load();
  // const fid = await fpPromise.then((fp) => fp.get());
  // const { visitorId } = fid;
  try {
    const request = await axios(`${url}/authenticate/google`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
        // Fingerprint: visitorId
      },
      data: { accessToken }
    });
    return request;
  } catch (error) {
    return error.request;
  }
}

export async function forgotPassword(data) {
  // const fpPromise = FingerprintJS.load();
  // const fid = await fpPromise.then((fp) => fp.get());
  // const { visitorId } = fid;
  try {
    const request = await axios(`${url}/password/forgot`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
        // Fingerprint: visitorId
      },
      data
    });
    return request;
  } catch (error) {
    return error.request;
  }
}

export async function resetPassword(data) {
  // const fpPromise = FingerprintJS.load();
  // const fid = await fpPromise.then((fp) => fp.get());
  // const { visitorId } = fid;
  try {
    const request = await axios(`${url}/password/reset`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
        // Fingerprint: visitorId
      },
      data
    });
    return request;
  } catch (error) {
    return error.request;
  }
}

export async function updatePassword(data, authToken) {
  // const fpPromise = FingerprintJS.load();
  // const fid = await fpPromise.then((fp) => fp.get());
  // const { visitorId } = fid;
  const token = isBrowser ? localStorage.getItem('veezu_token') : '';
  try {
    const request = await axios(`${url}/password/update`, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token || authToken}`
        // Fingerprint: visitorId
      },
      data
    });
    return request;
  } catch (error) {
    return error.response;
  }
}

export async function getSSOOptions() {
  // const fpPromise = FingerprintJS.load();
  // const fid = await fpPromise.then((fp) => fp.get());
  // const { visitorId } = fid;
  try {
    const request = await axios(`${url}/singlesignon`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
        // Fingerprint: visitorId
      }
    });
    return request;
  } catch (error) {
    if (error?.response?.status >= 500) {
      toast.error(
        error?.response?.data?.message ||
          error?.response?.statusText ||
          'Something went wrong, please try again later.',
        {
          autoClose: false,
          closeOnClick: false,
          draggable: false
        }
      );
    }
    return error.request;
  }
}

export async function getLoginMethod(email) {
  // const fpPromise = FingerprintJS.load();
  // const fid = await fpPromise.then((fp) => fp.get());
  // const { visitorId } = fid;
  try {
    const request = await axios(`${url}/authenticationmethod/${email}`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
        // Fingerprint: visitorId
      }
    });
    return request;
  } catch (error) {
    return error.request;
  }
}
