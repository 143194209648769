import axios from 'axios';
import { navigate } from 'gatsby';
import { getRecoil, setRecoil } from 'recoil-nexus';
import { toast } from 'react-toastify';
// import FingerprintJS from '@fingerprintjs/fingerprintjs';

// State
// import { profileState, traceState, fingerPrintState } from 'state';
import { profileState, traceState } from 'state';

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== 'undefined';
// const url = `https://sandbox.webbooker.co.uk`;
// const url = `https://production.webbooker.co.uk`;
const url = process.env.GATSBY_API_ENDPOINT;

const customAxios = axios.create({
  baseURL: url,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
});

export const requestHandler = async (request) => {
  // let visitorId = getRecoil(fingerPrintState);
  // if (visitorId === '') {
  //   const fpPromise = FingerprintJS.load();
  //   const fid = await fpPromise.then((fp) => fp.get());
  //   visitorId = fid.visitorId;
  // }
  let token = isBrowser ? localStorage.getItem('veezu_token') : '';
  if (window.location.pathname === '/guest/booking/' || window.location.pathname === '/guest/booking') {
    token = isBrowser ? localStorage.getItem('veezu_guest_token') : '';
  }
  request.headers.Authorization = `Bearer ${token}`;
  request.headers['Content-Encoding'] = 'gzip';
  // request.headers.Fingerprint = visitorId;F
  return request;
};

const responseHandler = async (res) => {
  if (res.status === 200) {
    // Get the Latest Profile Whenever the Headers Nonce Vale is Different that saved headers_nonce value in profile state.
    const profile = getRecoil(profileState);

    if (profile) {
      const isRoleChanged = profile.role.value !== res.headers.role;
      const shouldFetchProfile =
        profile.customisation_nonce !== res.headers['customisation-nonce'] ||
        profile.configuration_nonce !== res.headers['configuration-nonce'] ||
        isRoleChanged;
      let token = isBrowser ? localStorage.getItem('veezu_token') : '';
      if (window.location.pathname === '/guest/booking/' || window.location.pathname === '/guest/booking') {
        token = isBrowser ? localStorage.getItem('veezu_guest_token') : '';
      }
      if (shouldFetchProfile) {
        const { status, data } = await axios(`${url}/profile`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (status === 200) {
          const obj = {
            ...data,
            customisation_nonce: res.headers['customisation-nonce'],
            configuration_nonce: res.headers['configuration-nonce'],
            customisation_id: res.headers.customisation
          };

          setRecoil(profileState, obj);
        }
      }
    }
    // Function End - Get The Latests Profile
    // -----------------------------------------------------------------------------------
    // Get Trace Value from Header for All POST, PUT, DELETE Requests.
    if (res.config.method !== 'get') {
      setRecoil(traceState, res.headers.trace);
    }
  }
  if (res.request.status === 401) {
    localStorage.setItem('veezu_token', '');
    navigate('/login/');
  }
  return res;
};

const errorHandler = (err) => {
  // Check if the user is online
  if (!navigator.onLine) {
    toast.error('It seems there might be an issue with your internet connection. Please check and try again.');
  }

  // Check if there's backend error
  if (err.response.status >= 500 && err.response.status !== 503) {
    toast.error(err?.response?.data?.message || 'Something went wrong, please try again later.', {
      autoClose: false,
      closeOnClick: false,
      draggable: false
    });
  }

  if (err.request.status === 401) {
    // localStorage.setItem('veezu_token', '');
    localStorage.removeItem('veezu_token');
    navigate('/login/');
  }
  if (err.request.status === 429) {
    alert(err.response.data);
  }
  if (err.request.status === 403) {
    // navigate('/admin/');
  }
  // if (err.request.status === 0 && err.code === 'ERR_NETWORK') {
  //   toast.error('Something went wrong, please try again later.');
  // }

  return err.response;
};

customAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

customAxios.interceptors.response.use(
  (res) => responseHandler(res),
  (err) => errorHandler(err)
);

export default customAxios;
