/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';
import { useKey } from 'react-use';
import { PublicClientApplication } from '@azure/msal-browser';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

// Components
import Seo from 'components/Seo';
import Header from 'components/Header/LoginHeader';
import PDFComponent from 'components/PDFViewer';
// UI Components
import { FormInput, FormInputWithPassword } from 'components/UI/FormInput';
import { SuccessAlert } from 'components/UI/Alerts';
import { PrimaryButton } from 'components/UI/Button';
// Assets
import { EmailIcon, PasswordIcon, HidePasswordIcon } from 'components/UI/Svgs';
// AJAX
import {
  login,
  forgotPassword,
  updatePassword,
  loginWithAzure,
  loginWithGoogle,
  getSSOOptions,
  getLoginMethod
} from 'ajax/auth';
// UI
import ReactModal from 'components/UI/ReactModal';
import { SimpleInput } from 'components/FormHelpers';

function LoginPage() {
  // Toggle Password State
  const [togglePassword, setTogglePassword] = useState(true);
  // Toggle Reset Form | Login Form
  const [showResetForm, setShowResetForm] = useState(false);
  // Reset Password Feedback
  const [showResetFeedback, setShowResetFeedback] = useState(false);
  // Profile Default Route
  const [defaultRoute, setDefaultRoute] = useState('/booking/create/');
  // SSO
  const [sso, setSso] = useState(null);
  const [loginMethod, setLoginMethod] = useState('');
  const [ssoPopup, setShowSSOPopup] = useState(false);
  // User Migration Password Reset
  const [newPassword, setNewPassword] = useState({
    showModal: false,
    old: '',
    new: '',
    confirm: '',
    token: ''
  });
  // Login Response
  const [loginResponse, setLoginResponse] = useState(null);
  // Ajax Error Handling
  const [ajaxError, setAjaxError] = useState({ status: 0, message: '' });
  // Show Terms
  const [showTerms, setShowTerms] = useState(false);
  // Token State
  const [authData, setAuthData] = useState({ token: '', identity: '' });
  // Creating Schema for Login Form Validation
  const LoginSchema = Yup.object().shape({
    identity: Yup.string().email('Invalid email').required('Required'),
    password: showResetForm || loginMethod !== 'Local' ? null : Yup.string().required('Required')
  });

  // Capture Email and Password with Formik
  const formik = useFormik({
    initialValues: {
      identity: '',
      password: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async (data) => {
      if (ajaxError.status === 401) {
        return setAjaxError({ status: 0, message: '' });
      }
      if (showResetForm) {
        if (showResetFeedback) {
          setShowResetFeedback(false);
          return setShowResetForm(false);
        }
        const request = await forgotPassword({ identity: data.identity });
        if (!request)
          return setAjaxError({
            status: 500,
            message:
              'Sorry, an error has occurred, please try again or contact the administrator if the problem persists.'
          });
        if (request.status === 200) {
          return setShowResetFeedback(true);
        }
      } else {
        const loginFormData = {
          identity: data.identity,
          password: data.password
        };

        const request = await login(loginFormData);
        if (request.status === 401) {
          toast.error('Authentication Failed');
          return setAjaxError({ status: 401, message: '' });
        }

        // Set Response data
        setLoginResponse(request);

        // Display Modal to Reset Password
        if (request.data.reset) {
          return setNewPassword({ ...newPassword, showModal: true, token: request.data.token });
        }

        // Close Modal if reset was previously opened
        if (newPassword.showModal) {
          setNewPassword({ ...newPassword, showModal: false });
        }

        return loginHandler(request);
      }
      return null;
    }
  });

  // Toggle Password Handler
  const togglePasswordHandler = () => setTogglePassword(!togglePassword);

  // Toggle Forms: Login and Reset
  const toggleFormHandler = () => setShowResetForm(!showResetForm);

  // Accepts TOS Handler
  const acceptTermsHandler = async () => navigate(defaultRoute);

  // Decline TOS Handler
  const declineTermsHandler = async () => {
    window.localStorage.setItem('veezu_token', '');
    localStorage.setItem('veezu_user_id', '');
    setValues({
      identity: '',
      password: ''
    });
    setFieldTouched('identity', false);
    setFieldTouched('password', false);
    setShowTerms(false);
  };

  // Login Handler
  const loginHandler = async (request) => {
    setSubmitting(true);
    try {
      // Fetch Profile
      const apiUrl = process.env.GATSBY_API_ENDPOINT;
      const headers = {
        Authorization: `Bearer ${request.data.token}`
      };
      const profileRawResponse = await fetch(`${apiUrl}/profile`, { headers });
      const profileResponse = await profileRawResponse.json();

      let defaultRouteLocal = '/booking/create/';
      if (profileRawResponse.status === 200) {
        console.log('Profile is ', profileResponse);
        defaultRouteLocal = profileResponse?.options?.defaults?.module || '/booking/create/';
        if (profileResponse?.role?.value === 'Administrator' && !profileResponse?.options?.maintenance?.online) {
          defaultRouteLocal = '/management/configuration';
        }
        setDefaultRoute(defaultRouteLocal);
      }
      // Display T&Cs Based on Authentication termsAccepted State
      // const termsConditionsAccepted = request?.data?.termsConditionsAccepted;
      // const privacyPolicyAccepted = request?.data?.privacyPolicyAccepted;
      const termsConditionsAccepted =
        request?.headers['user-terms-conditions-accepted'] === 'Yes' ||
        request?.headers['user-terms-conditions-accepted'] === 'NotRequired';
      const privacyPolicyAccepted =
        request?.headers['user-privacy-policy-accepted'] === 'Yes' ||
        request?.headers['user-privacy-policy-accepted'] === 'NotRequired';
      if (request.status === 200) {
        if (termsConditionsAccepted && privacyPolicyAccepted) {
          localStorage.setItem('veezu_token', request?.data?.token);
          localStorage.setItem('veezu_user_id', request?.data?.identity);
          return navigate(defaultRouteLocal);
        }
        if (!termsConditionsAccepted || !privacyPolicyAccepted)
          setAuthData({ token: request?.data?.token, identity: request?.data?.identity });
        return setShowTerms(true);
      }
      return null;
    } catch (error) {
      console.error('Error fetching profile:', error);
      return null;
    } finally {
      setSubmitting(false);
    }
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    isSubmitting,
    setSubmitting
  } = formik;

  const PasswordMatchSchema = Yup.object({
    newPassword: Yup.string().required('Required'),
    confirmNewPassword: Yup.string()
      .required('Required')
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
  });

  // Capture Password
  const updatePasswordformik = useFormik({
    initialValues: {
      newPassword: '',
      confirmNewPassword: ''
    },
    validationSchema: PasswordMatchSchema,
    onSubmit: async (data) => {
      const response = await updatePassword(
        {
          currentPassword: values.password,
          newPassword: data.newPassword
        },
        newPassword.token
      );
      if (response.status !== 200) {
        if (response.data?.type === 'Validation') {
          return response.data?.data?.map((error) => toast.error(error.message));
        }
        if (response.data?.data) {
          return response.data?.data?.map((error) => toast.error(error.message));
        }
        return toast.error(response.data.message);
      }
      loginHandler(loginResponse);
      setNewPassword({ ...newPassword, showModal: false });
      return toast.success('Password Updated.');
    }
  });

  const {
    values: updateValues,
    errors: updateErrors,
    touched: updateTouched,
    handleChange: updateHandleChange,
    handleSubmit: updateHandleSubmit,
    isSubmitting: updateIsSubmitting
  } = updatePasswordformik;

  const handleLoginWithAzure = async () => {
    try {
      setSubmitting(true);
      if (!sso || !sso.azure) return;
      if (!sso.azure.enabled) return;

      const configuration = {
        auth: {
          clientId: sso.azure.clientId,
          authority: `https://login.microsoftonline.com/${sso.azure.tenantId}`,
          redirectUri: process.env.GATSBY_AZURE_REDIRECT_URL
        }
      };

      const msalInstance = new PublicClientApplication(configuration);

      const loginRequest = {
        scopes: ['https://webbooker.co.uk/user.login'],
        loginHint: values.identity
      };

      if (values.identity === '') {
        loginRequest.prompt = 'select_account';
      }

      await msalInstance.initialize();
      const data = await msalInstance.loginPopup(loginRequest);
      if (data) {
        const payload = { accessToken: data.accessToken };
        const request = await loginWithAzure(payload);
        if (request.status !== 200) {
          toast.error('Authentication Failed');
          return;
        }
        localStorage.setItem('login_type', 'azure');
        await loginHandler(request);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSubmitting(false);
    }
  };

  const handleLoginWithGoogle = async (accessToken) => {
    if (!sso || !sso.google) return;
    if (!sso.google.enabled) return;

    try {
      setSubmitting(true);
      setShowSSOPopup(false);
      const request = await loginWithGoogle(accessToken);
      if (request.status !== 200) {
        toast.error('Authentication Failed');
        setSubmitting(false);
        return;
      }
      localStorage.setItem('login_type', 'google');
      await loginHandler(request); // Add await here
    } catch (err) {
      setSubmitting(false);
    } finally {
      setShowSSOPopup(false);
    }
  };

  const handleSSOOptions = () => {
    formik.setErrors({});

    if (!sso || (!sso.azure.enabled && !sso.google.enabled)) return;

    setShowSSOPopup(true);

    // if (sso.azure.enabled && sso.google.enabled) {
    //   setShowSSOPopup(true);
    // } else if (sso.azure.enabled && !sso.google.enabled) {
    //   handleLoginWithAzure();
    // } else if (!sso.azure.enabled && sso.google.enabled) {
    //   handleLoginWithGoogle();
    // }
  };

  const validateForm = () => {
    if (values.identity === '') {
      return false;
    }
    return true;
  };

  const customFormSubmissionHandler = async (e) => {
    if (e) e.preventDefault();

    // Validate form
    formik.setTouched({ identity: true });
    formik.setErrors({ identity: 'Required' });

    if (!sso) {
      // alert("Please wait while we're fetching the login method.");
      return;
    }

    if (!validateForm()) return;

    if (loginMethod === 'Local') {
      handleSubmit();
      return;
    }

    try {
      setSubmitting(true);
      const response = await getLoginMethod(values.identity);
      if (response.status === 200) {
        setLoginMethod(response.data.method);
        switch (response.data.method) {
          case 'Local':
            break;
          case 'Azure':
            handleLoginWithAzure();
            break;
          case 'Google':
            // handleLoginWithGoogle();
            break;
          default:
            console.log('Unsupported login method');
        }
      }
    } catch (error) {
      console.error('Error fetching login method:', error);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    getSSOOptions().then((response) => setSso(response?.data?.singleSignOn));
  }, []);

  // Clear loginMethod if identity changes
  useEffect(() => {
    if (loginMethod !== '') {
      setLoginMethod('');
      if (values.password !== '') {
        setFieldValue('password', '');
      }
    }
  }, [values.identity]);

  const predicate = (event) => event.shiftKey && event.key === 'Enter';
  useKey(predicate, (e) => e.shiftKey && e.key === 'Enter' && handleSSOOptions());

  const predicateFormSubmit = (event) => event.key === 'Enter';
  useKey(predicateFormSubmit, (e) => !e.shiftKey && e.code === 'Enter' && customFormSubmissionHandler());

  const predicateClosePopup = (event) => event.key === 'Escape';
  useKey(predicateClosePopup, () => setShowSSOPopup(false));

  return (
    <>
      <Seo title="Login - Veezu" />
      <Header />
      <main>
        <div className="mx-auto mt-6 max-w-[416px] px-8 md:mt-20 md:px-0">
          <h2 className="h3-mobile md:h2-desktop mb-4 text-center md:mb-10">Welcome to eBooker</h2>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={customFormSubmissionHandler}>
              <FormInput
                type="email"
                name="identity"
                placeholder="E-Mail"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.identity}
                icon={<EmailIcon />}
                autoFocus={loginMethod !== 'Local'}
                error={errors.identity && touched.identity}
                errorMessage={errors.identity}
                className="mb-4"
              />
              {showResetForm && showResetFeedback && (
                <SuccessAlert
                  title="A reset link will be emailed if the supplied address is registered"
                  className="mb-2"
                />
              )}
              {!showResetForm && loginMethod === 'Local' && (
                <FormInputWithPassword
                  type={togglePassword ? 'password' : 'text'}
                  name="password"
                  placeholder="Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  icon={<PasswordIcon />}
                  error={(errors.password && touched.password) || ajaxError.status === 401}
                  errorMessage={ajaxError.status === 401 ? ajaxError.message : errors.password}
                  className="mb-4"
                  showPasswordIcon={<HidePasswordIcon togglePassword={togglePassword} />}
                  togglePasswordHandler={togglePasswordHandler}
                  setAjaxError={setAjaxError}
                  autoFocus
                />
              )}

              {!showResetFeedback && loginMethod === 'Local' && (
                <div className="mb-4 text-right md:mb-14">
                  <span>
                    {showResetForm ? 'Back to' : 'Forgot your password?'}{' '}
                    <button className="font-semibold text-primary-red" type="button" onClick={toggleFormHandler}>
                      {showResetForm ? 'Log In.' : 'Reset'}
                    </button>
                  </span>
                </div>
              )}

              {sso?.google.enabled && loginMethod === 'Google' ? (
                // <PrimaryButton
                //   type="button"
                //   text="Login with Google"
                //   onClick={() => alert('TO DO Google SSO')}
                //   className="!w-[180px]"
                // />
                <div className="mt-4 w-full">
                  <GoogleOAuthProvider clientId={sso.google.clientId}>
                    <GoogleLogin
                      onSuccess={(tokenResponse) => handleLoginWithGoogle(tokenResponse.credential)}
                      onError={(error) => console.error(error)}
                    />
                  </GoogleOAuthProvider>
                </div>
              ) : (
                <PrimaryButton
                  type="submit"
                  className={!showResetForm ? 'w-full' : '!w-[220px]'}
                  text={
                    showResetForm
                      ? showResetFeedback
                        ? 'Login'
                        : 'Reset Password'
                      : loginMethod === 'Local'
                      ? 'Login'
                      : 'Next'
                  }
                  disabled={isSubmitting}
                  readOnly={!sso}
                  // onClick={(e) => {
                  //   if (e.shiftKey) {
                  //     handleSSOOptions();
                  //   } else {
                  //     handleSubmit();
                  //   }
                  // }}
                  // onClick={customFormSubmissionHandler}
                >
                  {!isSubmitting && !showResetForm && loginMethod !== 'Local' && (
                    <FontAwesomeIcon icon={faArrowRight} size="lg" />
                  )}
                </PrimaryButton>
              )}
            </Form>
          </FormikProvider>
        </div>
      </main>
      {showTerms && (
        <div className="fixed inset-0 bg-black/50 grid place-items-center z-10">
          <PDFComponent
            successCb={acceptTermsHandler}
            declineCb={declineTermsHandler}
            token={authData.token}
            userId={authData.identity}
          />
        </div>
      )}
      {newPassword.showModal && (
        <ReactModal>
          <div className="w-full h-full md:h-auto px-4 md:px-8 lg:px-12 max-w-4xl overflow-y-auto">
            <div className="bg-white p-4 md:p-8 rounded relative">
              <h2 className="h4-desktop mb-10 text-center">Change Password</h2>
              <FormikProvider value={updatePasswordformik}>
                <Form autoComplete="off" noValidate onSubmit={updateHandleSubmit}>
                  <div className="grid gap-x-8 md:grid-cols-1 max-w-3xl">
                    <div>
                      <SimpleInput
                        field={{
                          label: 'New Password',
                          type: 'password',
                          name: 'newPassword',
                          required: true
                        }}
                        value={updateValues.newPassword}
                        handleChange={updateHandleChange}
                        errors={updateErrors}
                        touched={updateTouched}
                      />
                    </div>
                    <div>
                      <SimpleInput
                        field={{
                          label: 'Confirm New Password',
                          type: 'password',
                          name: 'confirmNewPassword',
                          required: true
                        }}
                        value={updateValues.confirmNewPassword}
                        handleChange={updateHandleChange}
                        errors={updateErrors}
                        touched={updateTouched}
                      />
                    </div>
                  </div>
                  <div className="text-right">
                    <PrimaryButton type="submit" text="Save" disabled={updateIsSubmitting} />
                  </div>
                </Form>
              </FormikProvider>
            </div>
          </div>
        </ReactModal>
      )}
      {ssoPopup && (
        <ReactModal>
          <div className="w-full h-full md:h-auto px-4 md:px-8 lg:px-12 max-w-4xl overflow-y-auto">
            <div className="bg-white p-4 md:p-8 rounded relative">
              <h2 className="h4-desktop mb-10 text-center">Select Identity Provider</h2>
              <div className="flex gap-4 justify-center items-center grid-cols-3">
                {sso.azure.enabled && (
                  <PrimaryButton type="button" text="Azure" onClick={handleLoginWithAzure} className="!w-[180px]" />
                )}
                {sso.google.enabled && loginMethod === 'Google' && (
                  // <PrimaryButton
                  //   type="button"
                  //   text="Login with Google"
                  //   onClick={() => alert('TO DO Google SSO')}
                  //   className="!w-[180px]"
                  // />
                  <GoogleOAuthProvider clientId={sso.google.clientId}>
                    <div className="relative overflow-hidden">
                      <div className="absolute inset-0 opacity-0 overflow-hidden">
                        <GoogleLogin
                          onSuccess={(tokenResponse) => handleLoginWithGoogle(tokenResponse.credential)}
                          onError={(error) => console.error(error)}
                        />
                      </div>
                      <PrimaryButton
                        type="button"
                        className="absolute inset-0 button pointer-events-none"
                        text="Google"
                      />
                    </div>
                  </GoogleOAuthProvider>
                )}
                <PrimaryButton type="button" text="Cancel" onClick={() => setShowSSOPopup(false)} />
              </div>
            </div>
          </div>
        </ReactModal>
      )}
    </>
  );
}

export default LoginPage;
